import { graphql } from 'gatsby';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import Color from 'color';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Grid from '../components/grid';
import Box from '../components/box';
import Content from '../components/content.js';
import Card from '../components/card';
import TitleCardsPill from '../components/title-cards-pill';
import SharePanel from '../components/share-panel';
import IntroTextWrapper from '../components/intro-text-wrapper';
import VariableFontTitle from '../components/variable-font-title';
import Subscribe from '../components/subscribe';
import PagePaddingTop from '../components/page-padding-top';
import HorizontalRule from '../components/horizontal-rule';
import { goodColors } from '../components/colors';

export default function Article({ data }) {
  const theme = useContext(ThemeContext);
  const tags = data.article.tag
    .map((tag) => tag.title)
    .sort()
    .join(', ');
  const date = format(new Date(data.article.datetime), 'MMMM d, yyyy');

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/news');
  }, []);

  return (
    <PagePaddingTop
      css={css`
        background-color: ${data.article.color
          ? Color(data.article.color?.value).lighten(0.35).hex()
          : 'var(--white)'};
        padding-bottom: 0.1px;
      `}
    >
      <SEO
        title={data.article.title}
        description={
          (data.article.content.length > 0 && data.article.content
                .filter((el) => el._type === 'complexBlockWrapper')[0])
            ? data.article.content
                .filter((el) => el._type === 'complexBlockWrapper')[0]
                ._rawText.map((el) => el.children)
                .flat()
                ?.map((el) => el?.text)
                .join(' ')
            : null
        }
        imageID={data.article.featuredImage?.asset._id}
      />
      <Box x="10" y="6">
        <IntroTextWrapper monoText={`${tags ? `${tags} — ` : ''}${date}`}>
          <VariableFontTitle
            css={css`
              margin-bottom: var(--margin9);

              @media (max-width: 800px) {
                margin-bottom: 4.5rem;
              }
            `}
            text={data.article.title}
            stenciling={data.article.type?.stenciling}
            weight={data.article.type?.weight}
            size={data.article.type?.size}
            alignment={data.article.type?.alignment}
          />
        </IntroTextWrapper>
      </Box>
      {data.article.content.length > 0 && (
        <Content
          css={css`
            padding: 0
              calc(
                var(--margin) + var(--gutterHalf) +
                  (((100% - 2 * var(--margin)) - 11 * var(--gutter)) / 12)
              );

            @media (min-width: 800px) and (max-width: 1400px) {
              padding: 0 var(--margin);
            }

            @media (max-width: 1024px) {
              .half {
                width: 100%;
                flex-basis: 100%;
              }
            }

            .content--complex-block > div {
              grid-column: 1 / span 12;
            }

            .content--quote,
            .content--code {
              grid-template-columns: repeat(10, 1fr);

              @media (max-width: 1024px) {
                grid-template-columns: repeat(12, 1fr);
              }

              @media (max-width: 800px) {
                display: block;
              }

              > div {
                grid-column: 2 / span 8;

                @media (max-width: 1024px) {
                  grid-column: 2 / span 10;
                }
              }
            }
          `}
          blocks={data.article.content}
        />
      )}
      <Box
        x={10}
        y={12}
        css={css`
          margin-top: 0 !important;
        `}
      >
        <Grid columns={8}>
          <BlockContent content={data.article._rawAddendum} />
        </Grid>
      </Box>
      <SharePanel saveId={data.article.id} invertPopupShare={true} />
      {data.article.relatedProjects.length > 0 && (
        <>
          <Box x={10} y={9}>
            <TitleCardsPill 
              heading="Related Work" 
              pillText="All Work" 
              pillLink="/work"
              columns={3}
            >
              {data.article.relatedProjects.map((project) => (
                <Card
                  url={`/project/${project.slug.current}`}
                  image={project.featuredImage}
                  imageCrop={false}
                  monoText={project.discipline.map((item) => item.title).join(', ')}
                  text={project.title}
                />
              ))}
            </TitleCardsPill>
          </Box>
          {data.article.relatedArticles.length > 0 && <Box>
            <HorizontalRule />
          </Box>}
        </>
      )}
      {data.article.relatedArticles.length > 0 && (
        <Box x={10} y={9}>
          <TitleCardsPill 
            heading="Related Writing" 
            pillText="All Writing" 
            pillLink="/news"
            columns={3}
          >
            {data.article.relatedArticles.map((article) => (
              <Card
                url={article.url ? article.url : `/article/${article.slug.current}`}
                external={article.externalArticle}
                externalTitle={article.publication}
                image={article.featuredImage}
                imageCrop={true}
                imageOverlay={(article.externalArticle) ? Color(goodColors[article.title.length % goodColors.length]).lighten(0.3).hex() : false}
                monoText={article.tag.map((item) => item.title).join(', ')}
                text={article.title}
              />
            ))}
          </TitleCardsPill>
        </Box>
      )}
      <Box
        css={css`
          background-color: var(--white);
        `}
      >
        <Subscribe />
      </Box>
    </PagePaddingTop>
  );
}

export const query = graphql`
  query ArticleTemplateQuery($slug: String!) {
    article: sanityArticle(slug: { current: { eq: $slug } }) {
      id
      title
      featuredImage {
        ...Image
      }
      type {
        stenciling
        weight
        size
        alignment
      }
      color {
        value
      }
      datetime
      tag {
        title
        slug {
          current
        }
      }
      content {
        ... on SanityComplexBlockWrapper {
          _key
          _type
          _rawText(resolveReferences: { maxDepth: 10 })
          width
        }
        ... on SanityRichImage {
          _key
          _type
          image {
            ...Image
            asset {
              description
              metadata {
                dimensions {
                  aspectRatio
                }
              }
              width
              height
            }
          }
          description
          width
          captionCard {
            hasCard
            _rawCaption(resolveReferences: { maxDepth: 10 })
            linkText
            linkURL
            position
          }
          hotSpots {
            hasHotSpots
            hotSpots {
              images {
                ...Image
                asset {
                  description
                  metadata {
                    dimensions {
                      aspectRatio
                    }
                  }
                }
              }
              x
              y
              _rawText(resolveReferences: { maxDepth: 10 })
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          video {
            asset {
              url
            }
          }
          description
          width
          poster {
            ...Image
          }
          autoplay
          muted
          scrubber
          loop
        }
        ... on SanityTripleImage {
          _key
          _type
          portraitImage {
            ...Image
          }
          topLandscapeImage {
            ...Image
          }
          bottomLandscapeImage {
            ...Image
          }
          reverse
          captionCard {
            hasCard
            _rawCaption(resolveReferences: { maxDepth: 10 })
            linkText
            linkURL
            position
          }
        }
        ... on SanityGallery {
          _key
          _type
          media {
            ... on SanityImage {
              _type
              ...Image
              asset {
                description
                metadata {
                  dimensions {
                    aspectRatio
                  }
                }
              }
            }
            ... on SanityVideo {
              _type
              video {
                asset {
                  url
                }
              }
            }
          }
        }
        ... on SanityQuote {
          _type
          _rawText(resolveReferences: { maxDepth: 10 })
          _rawAuthor(resolveReferences: { maxDepth: 10 })
          hideBox
        }
        ... on SanityArticle {
          _id
          _type
          slug {
            current
          }
          datetime
          tag {
            title
          }
          content {
            ... on SanityComplexBlockWrapper {
              _type
              _rawText(resolveReferences: { maxDepth: 10 })
            }
          }
        }
        ... on SanityEvent {
          _key
          _type
          _rawDateTime(resolveReferences: { maxDepth: 10 })
          _rawAdmission(resolveReferences: { maxDepth: 10 })
          _rawLocation(resolveReferences: { maxDepth: 10 })
          _rawAccessibility(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityInformation {
          _key
          _type
          title
          information {
            data
            _rawText(resolveReferences: { maxDepth: 10 })
          }
        }
        ... on SanityCode {
          _key
          _type
          title
          code
        }
        ... on SanityIframe {
          _key
          _type
          url
          aspectRatio
        }
        ... on SanityLink {
          _type
          text
          url
          _key
        }
      }
      _rawAddendum(resolveReferences: { maxDepth: 10 })
      relatedProjects {
        slug {
          current
        }
        title
        featuredImage {
          ...Image
        }
        discipline {
          title
        }
      }
      relatedArticles {
        slug {
          current
        }
        title
        externalArticle
        url
        publication
        datetime
        featuredImage {
          ...Image
        }
        tag {
          title
        }
      }
    }
  }
`;
