import React, { useState } from 'react';
import { css } from '@emotion/react';
import Form from './form';

export default function Subscribe({ className }) {
  const [buttonText, setButtonText] = useState('Subscribe');

  async function onSubmit(e, data) {
    e.preventDefault();

    setButtonText('Subscribing...');

    const response = await window
      .fetch('/api/subscribe', {
        method: 'POST',
        body: data,
      })
      .then(res => res.json());

    setButtonText(response);
  }

  return (
    <Form
      className={className}
      title="Stay in the know"
      subtitle="Subscribe to our newsletter to stay up to date with the latest BVN news."
      fields={[
        {
          type: 'text',
          name: 'name',
          placeholder: 'Name',
          required: true,
        },
        {
          type: 'email',
          name: 'email',
          placeholder: 'Email',
          required: true,
        },
      ]}
      buttonText={buttonText}
      onSubmit={onSubmit}
      submitErrorMessage="Please enter all required fields to sign up"
      css={css`
        padding: var(--margin8) var(--margin);

        @media (max-width: 1024px) {
          padding: var(--margin8) var(--gutter);
        }

        @media (max-width: 550px) {
          padding: var(--margin8) var(--gutterHalf);
        }
      `}
    />
  );
}
