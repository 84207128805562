import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function IntroTextWrapper({ className, monoText, children }) {
  return (
    <div className={className}>
      <h2
        className="type--mono-heading"
        css={css`
          margin-bottom: 2.2rem;
        `}
      >
        {monoText}
      </h2>
      <div
        className="type--heading-four"
        css={css`
          .type--variable-stencil,
          .type--variable {
            margin-top: var(--gutter);
          }
        `}
      >
        {children}
      </div>
    </div>
  );
}
