import { css } from '@emotion/react';
import React from 'react';

export default function VariableFontTitle({
  className,
  text,
  weight,
  size,
  stenciling,
  alignment,
}) {
  return (
    <h1
      className={`type--variable${stenciling ? '-stencil' : ''} ${className ? className : ''}`}
      css={css`
        font-size: ${size * 5 + 6}vw;
        letter-spacing: 0;
        font-variation-settings: 'wght' ${weight * 1000};
        text-align: ${alignment ? alignment : 'center'};
        word-break: break-word;
      `}
    >
      {text}
    </h1>
  );
}
